@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@400;600;700&display=swap");

@import "css/bootstrap.min.css";
@import "css/animate.css";
@import "css/magnific-popup.css";
@import "css/font-awesome.min.css";

/* --------------------------
:: 1.0 Base CSS Start
-------------------------- */

* {
  margin: 0;
  padding: 0;
}

body,
html {
  font-family: "Nunito", sans-serif !important;
}

a,
a:hover,
a:focus,
a:active {
  font-weight: 700;
  text-decoration: none;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

p {
  color: white;
}

li {
  list-style: none;
}

ul,
ol {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.section_padding_100 {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.middle-section h5 {
  width: 70vw;
  line-height: 1.5;
}

.welcome-section {
  text-align: center;
  padding: 100px 0 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.our-clientele-section {
  text-align: center;
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .section_padding_100_0 {
    padding: 100px 0 0 0;
} */

.mt-15 {
  margin-top: 15px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-150 {
  margin-top: 150px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-30 {
  margin-right: 30px;
}

.mr-50 {
  margin-right: 50px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-50 {
  margin-left: 50px;
}

.ml-100 {
  margin-left: 100px;
}

#preloader {
  overflow: hidden;
  background-color: #211b31;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999999;
}

.mosh-preloader {
  overflow: hidden;
  -webkit-animation: 1000ms linear 0s normal none infinite running
    mosh-preloader;
  animation: 1000ms linear 0s normal none infinite running mosh-preloader;
  background-color: transparent;
  border-color: #f1f2f3 #f1f2f3 #4a7aec;
  border-radius: 50%;
  border-style: solid;
  border-width: 4px;
  height: 5vw;
  margin: 0 auto;
  /* left: calc(50% - 25px); */
  position: relative;
  top: 50%;
  width: 5vw;
  z-index: 9;
}

@-webkit-keyframes mosh-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes mosh-preloader {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

#scrollUp {
  bottom: 0;
  font-size: 24px;
  right: 30px;
  width: 40px;
  background: #4a7aec;
  color: #fff;
  text-align: center;
  height: 40px;
  line-height: 38px;
  border-radius: 0;
  box-shadow: 2px 10px 8px rgba(0, 0, 0, 0.15);
}

.mosh-table {
  display: table;
  height: 100%;
  left: 0;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 2;
}

.mosh-table-cell {
  display: table-cell;
  vertical-align: middle;
}

.section-heading {
  margin-bottom: 100px;
}

.section-heading > p {
  font-weight: 700;
  color: #bec0cc;
  font-size: 12px;
  margin-bottom: 10px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.section-heading > h2 {
  font-size: 40px;
  margin: 0;
  font-weight: 900;
}

.section-heading > h5 {
  font-size: 22px;
  color: #abadbe;
  line-height: 1.8;
  font-weight: 500;
}

.mosh-btn {
  border-radius: 170px;
  border: 0 none;
  padding: 10px;
  background-color: #4a7aec;
  min-width: 150px;
  color: #fff;
  text-transform: uppercase;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.mosh-btn:hover {
  background-color: #1a3c8d;
  color: #fff;
}

.bg-overlay,
.bg-overlay-white {
  position: relative;
  z-index: 1;
}

.bg-overlay:after,
.bg-overlay-white:after {
  background-color: rgba(33, 27, 49, 0.88);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  position: absolute;
}

.bg-overlay-white:after {
  background-color: rgba(231, 235, 246, 0.89);
}

.bg-img {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* --------------------------
:: 2.0 Header Area CSS
-------------------------- */

.header_area {
  left: 0;
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 0;
  padding: 0 15px;
  height: 120px;
  background-color: transparent;
  -webkit-transition-duration: 700ms;
  transition-duration: 700ms;
}

.menu_area {
  position: relative;
  z-index: 2;
}

.menu_area #nav .nav-link {
  color: #fff;
  display: block;
  font-size: 15px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  padding: 0 15px;
}

.menu_area .dropdown-menu.show {
  border: none;
  box-shadow: 3px 5px 12px rgba(0, 0, 0, 0.15);
}

.menu_area .dropdown-item {
  font-size: 14px;
  font-weight: 700;
  color: #9597a6;
}

.menu_area #nav .nav-link:hover,
.menu_area #nav .nav-item.active .nav-link,
.menu_area .dropdown-item:hover {
  color: #4a7aec;
}

.login-register-btn a {
  color: #4a7aec;
  display: inline-block;
  font-weight: 500;
}

.login-register-btn a:hover {
  color: #fff;
}

.search-button > a {
  margin: 0 80px;
}

.navbar-nav {
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

/* Sticky CSS */

.header_area.sticky {
  background-color: #25499f;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 999;
}

/* --------------------------
:: 3.0 Welcome Area CSS
-------------------------- */

.landing-carousel {
  padding: 12vh 0 0 0;
  height: 85vh;
  width: 100vw;
  margin: 0 auto;
}

.carousel-inner img{
  display: flex;
  margin: 0 auto;
}

.carousel-item h2 {
  text-align: center;
  color: white;
  font-weight: 700;
  width: 40vw;
  margin: 0 auto;
  font-size: 40px;
}

.carousel-item img {
  width: 60vw;
  height: auto;
}

.welcome_area {
  position: relative;
  z-index: 2;
  height: 140vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/* -------------------------
:: 4.0 Service Area
------------------------- */

.mosh-products-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mosh-service-slides {
  position: relative;
  z-index: 1;
}

.single-service-area h2 {
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 20px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  color: #bec0cc;
}

.mosh-service-slides .center .single-service-area h2,
.single-service-area:hover h2 {
  color: #4a7aec;
}

.single-service-area h4 {
  margin-bottom: 20px;
}

/* -------------------------
:: 5.0 Clients Area
------------------------- */

.clients-logo-area {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-row-gap: 2vw;
}

.clients-logo-area > .grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clients-logo-area > div > img {
  max-width: 10vw;
  height: auto;
  padding: 0 0 50px 0;
}

/* -------------------------
:: 6.0 Features Area
------------------------- */

.mosh-portfolio .mosh-features-area {
  background-color: #211b31;
  position: relative;
  z-index: 1;
}

.features-img > img {
  width: 40%;
  position: absolute;
  bottom: -30px;
  left: 30px;
  z-index: 9;
}

.mosh-features-area .section-heading {
  margin-bottom: 60px;
}

.mosh-features-area .section-heading h2 {
  color: #fff;
}

.barfiller {
  background: #f4f4f4;
  border: none;
  border-radius: 0;
  box-shadow: none;
  height: 5px;
  margin-bottom: 5px;
  position: relative;
  width: 100%;
}

.barfiller .fill {
  display: block;
  position: relative;
  width: 0px;
  height: 100%;
  background: #4a7aec;
  z-index: 1;
}

.barfiller .tipWrap {
  display: none;
}

.barfiller .tip {
  margin-top: -35px;
  padding: 2px 4px;
  font-size: 15px;
  color: #4a7aec;
  left: 0px;
  position: absolute;
  z-index: 2;
  background: transparent;
  font-weight: 500;
}

.barfiller .tip:after {
  display: none;
}

.single_progress_bar p {
  margin-bottom: 10px;
}

/* -------------------------
:: 7.0 More Service Area
------------------------- */

.mosh-more-services-area {
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: 80vw;
}

.mosh-more-services-area .grid-item {
  padding: 30px;
}

.single-more-service-area {
  -webkit-transition-duration: 750ms;
  transition-duration: 750ms;
}

.single-more-service-area:hover {
  box-shadow: 0 10px 20px 10px rgba(0, 0, 0, 0.1);
}

.more-service-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.more-service-content > a {
  text-decoration: none;
}

.more-service-content img {
  width: 6vw;
  height: 6vw;
  margin-bottom: 4vw;
}

.more-service-content h4 {
  margin-bottom: 30px;
  text-transform: capitalize;
  font-weight: 700;
  color: #404551;
  line-height: 1.2;
}

.more-service-content p {
  color: #abadbe;
  font-size: 15px;
  font-weight: 500;
  margin-top: 0;
  line-height: 1.9;
}

/* -------------------------
:: 8.0 Portfolio Area
------------------------- */

.bradcumbContent h3 {
  font-size: 50px;
  font-weight: 700;
  color: #fff;
}

.mosh-gallery {
  display: grid;
  grid-row-gap: 100px;
  position: relative;
  padding: 0 0 120px 0;
}

.mosh-gallery > .grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1vw;
}

.gallery-content {
  padding: 100px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  margin: 0 auto;
}

.portfolio-menu > p {
  display: inline-block;
  padding: 0 15px;
  cursor: pointer;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  margin-bottom: 0;
}

.portfolio-menu > p:hover {
  color: #4a7aec;
}

.single_gallery_item {
  position: relative;
}

.single_gallery_item > img {
  width: 100%;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single_gallery_item:hover > img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="8" /></filter></svg>#filter');
  -webkit-filter: blur(8px);
  filter: blur(8px);
  filter: progid:DXImageTransform.Microsoft.Blur(pixelradius=8);
}

.gallery-hover-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(74, 122, 236, 0.85);
  opacity: 0;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; */
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.single_gallery_item:hover .gallery-hover-overlay {
  opacity: 1;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" */
}

.port-hover-text h4 {
  color: #fff;
  font-weight: 600;
  width: 80%;
  text-align: center;
  margin: 0 auto;
}

.port-hover-text > a {
  color: #fff;
  font-size: 15px;
}

/* -------------------------
:: 9.0 Workflow Area
------------------------- */

.workflow-img {
  position: relative;
  z-index: -10;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.single-workflow-area > h2 {
  font-size: 16px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 47px;
  flex: 0 0 47px;
  width: 47px !important;
  height: 47px !important;
  text-align: center;
  background-color: #f3f5f9;
  line-height: 47px;
  border-radius: 50%;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.workflow-content h4 {
  margin-bottom: 30px;
}

.single-workflow-area:hover h2 {
  background-color: #4a7aec;
  color: #fff;
}

.workflow-slides-area {
  position: relative;
  z-index: 99;
  top: -100px;
}

.mosh-workflow-slides .owl-dots {
  margin-top: 60px;
  text-align: center;
}

.mosh-workflow-slides .owl-dot {
  background-color: #daddeb;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  display: inline-block;
}

.mosh-workflow-slides .owl-dot.active {
  background-color: #4a7aec;
}

/* -------------------------
:: 10.0 CTA Area
------------------------- */

.mosh-call-to-action-area {
  position: relative;
  z-index: 1;
  background-attachment: fixed;
}

.cta-content .section-heading {
  margin-bottom: 0;
}

.cta-content .section-heading h2 {
  margin-bottom: 40px;
  color: #fff;
  font-size: 44px;
}

/* -------------------------
:: 11.0 FAQ Area
------------------------- */

.panel {
  background-color: #fff;
  border: 0 solid transparent;
  border-radius: 4px;
  box-shadow: 0 0 0 transparent;
  margin-bottom: 15px;
}

.single-accordion:last-of-type {
  margin-bottom: 0px;
}

.single-accordion h6 a {
  background-color: #4a7aec;
  border-radius: 0;
  color: #fff;
  display: block;
  margin: 0;
  font-weight: 400;
  padding: 15px 15px 15px 60px;
  position: relative;
  font-size: 15px;
  text-transform: capitalize;
}

.single-accordion h6 a.collapsed {
  background-color: #4a7aec;
  color: #fff;
}

.single-accordion h6 a span {
  font-size: 10px;
  position: absolute;
  left: 20px;
  text-align: center;
  top: 17px;
}

.single-accordion h6 a.collapsed span.accor-close {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

span.accor-open {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}

.single-accordion h6 a.collapsed span.accor-open {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
}

.single-accordion h6 {
  margin-bottom: 0;
  text-transform: uppercase;
}

.single-accordion .accordion-content {
  border-top: 0 solid transparent;
  box-shadow: none;
}

.single-accordion .accordion-content p {
  padding: 10px 15px 0;
  margin-bottom: 10px;
}

/* --------------------------
:: 12.0 Footer Area CSS
-------------------------- */

.footer-area {
  background-color: #211b31;
}

.top-footer-area {
  padding: 60px 10%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.top-footer-area p {
  width: 25vw;
}

.single-footer-widget h5 {
  color: #fff;
  font-size: 25px;
}

.single-footer-widget ul > li > a {
  color: rgba(226, 219, 243, 0.3);
  margin-bottom: 10px !important;
  display: block;
  font-weight: 500;
}

.single-footer-widget ul > li > a:last-child {
  margin-bottom: 0;
}

.single-footer-widget ul > li > a:hover {
  color: #ffffff;
}

.footer-social-info > a {
  color: #abadbe;
  padding: 0 15px;
  display: inline-block;
  font-size: 13px;
}

.footer-social-info > a:hover {
  color: #fff;
}

.footer-single-contact-info .contact-icon {
  margin-right: 1vw;
}

.contact-information p,
.contact-information a  {
  color: grey;
}

/* --------------------
:: 13.0 Breadcumb CSS
-------------------- */

.mosh-breadcumb-area {
  height: 500px;
  width: 100%;
  position: relative;
  z-index: 1;
}

.bradcumbContent h2 {
  color: #fff;
  font-size: 60px;
}

.bradcumbContent .breadcrumb {
  padding: 0;
  background-color: transparent;
  border-radius: 0;
  margin-top: 10px;
}

.bradcumbContent .breadcrumb-item a,
.bradcumbContent .breadcrumb-item {
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

/* -----------------------
:: 14.0 About Us CSS
----------------------- */

.mosh-breadcumb-area {
  background-size: 100vw 50vh;
  background-repeat: no-repeat;
  height: 53vh;
}

.mosh-aboutUs-area {
  padding: 100px 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mosh-about-us-content {
  width: 40vw;
}

.mosh-about-us-content p {
  color: #696969;
}

.few-words-from-ceo {
  overflow-x: hidden;
}

.mosh-about-us-content .section-heading {
  margin-bottom: 50px;
}

.few-words-contents,
.few-words-thumb {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
  height: 770px;
}

.few-words-contents {
  background-color: #211b31;
}

.few-words-text .section-heading {
  margin-bottom: 50px;
}

.few-words-text .section-heading h2 {
  color: #fff;
}

.few-words-text > p {
  margin-bottom: 0;
}

.ceo-meta-data .ceo-thumb {
  width: 52px;
  height: 52px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 52px;
  flex: 0 0 52px;
  border-radius: 50%;
  margin-right: 20px;
}

.ceo-meta-data .ceo-name h6 {
  color: #fff;
  margin-bottom: 10px;
  line-height: 1;
}

.ceo-meta-data .ceo-name p {
  margin-bottom: 0;
  line-height: 1;
  font-size: 12px;
  font-weight: 700;
}

/* -------------------------
:: 15.0 Services Area CSS
------------------------- */

.services-content-area {
  position: relative;
  z-index: 98;
  padding: 100px 0 200px 0;
}

.services-text h2 {
  font-size: 55px;
  margin-bottom: 50px;
}

.services-text p {
  line-height: 2;
  margin-bottom: 50px;
}

/* -------------------------
:: 16.0 Team Member Area
------------------------- */

.team-thumbnail {
  width: 189px;
  height: 189px;
  border-radius: 50%;
  margin: 0 auto 50px;
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.team-thumbnail:after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(74, 122, 236, 0.75);
  z-index: 2;
  border-radius: 50%;
  opacity: 0;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; */
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.team-thumbnail > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.team-meta-info {
  margin-bottom: 50px;
}

.team-meta-info h4 {
  line-height: 1;
  margin-bottom: 10px;
}

.team-meta-info span {
  font-size: 12px;
  font-weight: 900;
  color: #abadbe;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 35px;
  display: block;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.team-social-info {
  margin-top: 30px;
}

.team-social-info > a {
  font-size: 13px;
  color: #abadbe;
  display: inline-block;
  padding: 0 15px;
}

.single-team-slide:hover .team-meta-info span,
.team-social-info > a:hover {
  color: #4a7aec;
}

.single-team-slide:hover .team-thumbnail:after {
  opacity: 1;
  /* -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)" */
}

.mosh-team-slides {
  position: relative;
  z-index: 2;
}

.mosh-team-slides .owl-prev,
.mosh-team-slides .owl-next {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  line-height: 60px;
  text-align: center;
  top: 21%;
  margin-top: -30px;
  left: -25px;
  border-radius: 50%;
  color: #a2baff;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.mosh-team-slides .owl-next {
  left: auto;
  right: -25px;
}

.mosh-subscribe-newsletter-area {
  background-attachment: fixed;
}

.subscribe-newsletter-content p {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 2px;
}

.subscribe-newsletter-content h2 {
  font-size: 44px;
  margin-bottom: 30px;
}

.subscribe-newsletter-content form {
  position: relative;
  z-index: 1;
}

.subscribe-newsletter-content form > input {
  width: 60%;
  height: 55px;
  border-radius: 70px;
  border: none;
  padding: 0 30px;
  font-style: italic;
  font-size: 14px;
  color: #abadbe;
}

.subscribe-newsletter-content form > button {
  width: 150px;
  height: 55px;
  border-radius: 0 70px 70px 0;
  border: none;
  padding: 0 30px;
  font-size: 14px;
  color: #fff;
  background-color: #4a7aec;
  position: absolute;
  right: 20%;
  font-weight: 700;
  cursor: pointer;
  text-transform: uppercase;
  top: 0;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.subscribe-newsletter-content form > button:hover {
  color: #fff;
  background-color: rgb(16, 54, 145);
}

/* -------------------------
:: 17.0 Clients Area
------------------------- */

.mosh-clients-area {
  padding: 150px 8%;
}

.mosh-clients-testimonials-area {
  background-color: #211b31;
  position: relative;
  z-index: 1;
}

.mosh-clients-testimonials-area .section-heading h2 {
  color: #fff;
}

.testimonial-content span {
  font-size: 60px;
  color: #fff;
  line-height: 1;
  font-weight: 900;
}

.testimonial-content p {
  margin-bottom: 0;
}

.testimonials-meta .testimonial-thumb {
  width: 52px;
  height: 52px;
  margin: 0 auto 20px;
  border-radius: 50%;
  border: 0px solid #211b31;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  position: relative;
  z-index: 1;
  margin-top: 40px;
}

.testimonials-meta > h6 {
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 5px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.testimonials-meta > p {
  margin-bottom: 0;
  font-weight: 700;
  letter-spacing: 2px;
  font-size: 12px;
  text-transform: uppercase;
}

.testimonials-slides {
  position: relative;
  z-index: 1;
}

.testimonials-slides:before {
  position: absolute;
  width: 33.33333%;
  text-align: center;
  content: "Previous";
  top: 0;
  left: 0;
  z-index: -5;
  height: 100%;
  color: #fff;
}

.testimonials-slides:after {
  position: absolute;
  width: 33.33333%;
  text-align: center;
  content: "Next";
  top: 0;
  right: 0;
  z-index: -5;
  height: 100%;
  color: #fff;
}

.testimonials-slides .owl-prev,
.testimonials-slides .owl-next {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  line-height: 60px;
  text-align: center;
  top: 21%;
  margin-top: -30px;
  left: -25px;
  border-radius: 50%;
  color: #a2baff;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}

.testimonials-slides .owl-next {
  left: auto;
  right: -25px;
}

.testimonials-slides .single-testimonial-area .testimonial-content {
  display: none;
}

.testimonials-slides .center .single-testimonial-area .testimonial-content {
  display: block;
}

.single-testimonial-area:hover .testimonials-meta > h6,
.testimonials-slides .center .single-testimonial-area .testimonials-meta > h6 {
  color: #fff;
}

.single-testimonial-area:hover .testimonials-meta .testimonial-thumb {
  border: 3px solid #4a7aec;
}

/* Sonar CSS */

.testimonial-thumb:before {
  content: "";
  display: inline-block;
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  border-radius: 50%;
  top: 3px;
  left: 3px;
  -webkit-animation: sonar-effect 1s ease-in-out 0s infinite;
  animation: sonar-effect 1s ease-in-out 0s infinite;
  z-index: -1;
}

.testimonials-slides .center .testimonial-thumb:before {
  display: none;
}

@-webkit-keyframes sonar-effect {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    box-shadow: 0 0 0 3px #4a7aec, 0 0 5px 5px #4a7aec, 0 0 0 5px #4a7aec;
  }
  100% {
    box-shadow: 0 0 0 3px #4a7aec, 0 0 5px 5px #4a7aec, 0 0 0 5px #4a7aec;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

@keyframes sonar-effect {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.5;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    box-shadow: 0 0 0 3px #4a7aec, 0 0 5px 5px #4a7aec, 0 0 0 5px #4a7aec;
  }
  100% {
    box-shadow: 0 0 0 3px #4a7aec, 0 0 5px 5px #4a7aec, 0 0 0 5px #4a7aec;
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}

.mosh-cool-facts-area.service-page {
  width: 100%;
  position: relative;
  z-index: 1;
  height: 500px;
}

.mosh-cool-facts-area.service-page .counter-area h3,
.mosh-cool-facts-area.service-page .cool-facts-content p {
  color: #fff;
}

.skills-text > p {
  padding-right: 30px;
}

.skills-text > p:last-child {
  padding-right: 0;
}

/* -----------------------
:: 18.0 Blog Area CSS
----------------------- */

.blog-area {
  position: relative;
  z-index: 5;
}

.single-blog {
  margin-bottom: 100px;
}

.single-blog .blog-post-thumb {
  margin-bottom: 40px;
}

.single-blog h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.post-meta {
  margin-bottom: 10px;
}

.post-meta h6 {
  color: #b1b3c3;
  text-transform: capitalize;
  margin-bottom: 0;
  font-size: 12px;
}

.post-meta h6 a {
  color: #b1b3c3;
  display: inline-block;
  margin-right: 5px;
}

.post-meta h6 a:hover {
  color: #4a7aec;
}

.single-blog > a {
  color: #6270ff;
  font-size: 14px;
  text-transform: uppercase;
}

.single-blog p {
  margin-bottom: 40px;
}

.mosh-pagination-area .page-link {
  padding: 0;
  line-height: 42px;
  color: #007bff;
  border: none;
  border-radius: 0;
  width: 42px;
  height: 42px;
  font-size: 14px;
  text-align: center;
}

.page-item:first-child .page-link,
.page-item:last-child .page-link {
  margin-left: 0;
  border-radius: 0;
}

.blog-post-search-widget form {
  position: relative;
  z-index: 1;
}

.blog-post-search-widget form > input {
  width: 100%;
  height: 50px;
  border-top: none;
  border-left: none;
  border-radius: 0;
  border-right: none;
  border-bottom: 2px solid #e6e7f4;
}

.blog-post-search-widget form > button {
  position: absolute;
  right: 0;
  top: 0;
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.blog-post-archives > h5,
.blog-post-categories > h5,
.latest-blog-posts > h5,
.instagram-feeds > h5 {
  font-size: 18px;
  margin-bottom: 30px;
}

.blog-post-archives ul > li > a,
.blog-post-categories ul > li > a {
  color: #abadbe;
  font-size: 15px;
  display: block;
  padding: 5px 0;
  font-weight: 500;
}

.latest-blog-post-content h6 > a {
  color: #404551;
}

.latest-blog-post-content h6 > a:hover,
.blog-post-archives ul > li > a:hover,
.blog-post-categories ul > li > a:hover {
  color: #4a7aec;
}

.single-latest-blog-post {
  margin-bottom: 20px;
}

.latest-blog-post-thumb {
  width: 70px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70px;
  flex: 0 0 70px;
  margin-right: 20px;
}

.instagram-feeds ul > li {
  width: calc(33.3333333% - 8px);
  margin: 4px;
  display: inline-block;
  /* float: left; */
}

/* ----------------------
:: 19.0 Contact Page CSS
---------------------- */

.contact-area {
  display: flex;
  justify-content: space-between;
  padding: 150px 10%;
}

.contact-form-area {
  display: flex;
  flex-direction: column;
  width: 80vw;
  justify-content: flex-start;
}

.contact-left {
  width: 40vw;
}

.contact-right {
  width: 30vw;
}

.contact-form-area h2,
.contact-information h2 {
  font-size: 40px;
  margin-bottom: 50px;
  font-weight: 900;
}

.contact-form-area .form-control {
  font-size: 12px;
  color: #cbcddc;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ebebeb;
  border-radius: 0;
  height: 50px;
  margin-bottom: 10px;
  padding: 15px;
  font-style: italic;
}

.contact-form-area .form-control:hover,
.contact-form-area .form-control:focus {
  box-shadow: none;
}

.contact-form-area textarea.form-control {
  height: 140px;
}

.contact-social-info > a {
  color: #cbcddc;
  padding: 0 10px;
  display: inline-block;
}

.contact-social-info > a:hover {
  color: #4a7aec;
}

/* -----------------------
:: 20.0 Elements Area CSS
----------------------- */

.elements-title h2 {
  margin-bottom: 60px;
  font-size: 24px;
}

.mosh-btn.mosh-btn-2 {
  border: 2px solid #4a7aec;
  color: #404551;
  background-color: #fff;
}

.mosh-btn.mosh-btn-2:hover,
.mosh-btn.mosh-btn-2:focus {
  border: 2px solid #4a7aec;
  color: #fff;
  background-color: #4a7aec;
}

.mosh-btn.mosh-btn-3 {
  border: none;
  color: #404551;
  background-color: #fff;
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.15);
}

.mosh-btn.mosh-btn-3:hover,
.mosh-btn.mosh-btn-3:focus {
  color: #fff;
  background-color: #4a7aec;
}

.mosh-cool-facts-area {
  width: 100%;
}

.mosh-cool-facts-area .counter-area > h3 {
  color: #4a7aec;
  font-size: 60px;
  margin-bottom: 0;
  line-height: 1;
}

.mosh-cool-facts-area .cool-facts-content > p {
  color: #292d4f;
}

/* -----------------------
:: 21.0 Not Found Page CSS
----------------------- */
.not-found-icon > .animated {
  animation-iteration-count: infinite;
}

.not-found-icon {
  width: 15vw;
  height: 15vw;
}

.not-found-caption {
  font-size: '30vw';
}

.not-found .btn {
  color: white;
  text-decoration: none;
  border-radius: 70em;
  padding: 8px 60px;
  background-color: #4a7aec;
}

.not-found .btn:hover {
  background-color: #1a3c8d;
  color: #fff;
}

/* --------------------------------
============= The End =============
---------------------------------*/
